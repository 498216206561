<template>
  <router-view v-if="online" />
</template>
<script>
import { QSpinnerGears } from "quasar";
import { useQuasar } from "quasar";

import pb from "@/pocketbase";

export default {
  name: "App",
  data: () => ({
    $q: useQuasar(),
    online: false,
  }),
  async mounted() {
    await this.getHealth();
  },
  methods: {
    async getHealth() {
      this.$q.loading.show({
        spinner: QSpinnerGears,
      });
      try {
        const response = await fetch(`${pb.baseUrl}/api/health`);
        // const json = await response.json();
        this.online = true;
      } catch (ex) {
        let seconds = 30;
        const dialog = this.$q
          .dialog({
            persistent: true,
            color: "negative",
            title: "Servicio no disponible",
            ok: false,
            message: `Intentando conectar nuevamente en ${seconds} segundo${
              seconds > 1 ? "s" : ""
            }.`,
          })
          .onDismiss(() => {
            clearTimeout(timer);
            this.getHealth();
          });

        const timer = setInterval(() => {
          seconds--;

          if (seconds > 0) {
            dialog.update({
              message: `Intentando conectar nuevamente en ${seconds} segundo${
                seconds > 1 ? "s" : ""
              }.`,
            });
          } else {
            clearInterval(timer);
            dialog.hide();
          }
        }, 1000);
      }
      this.$q.loading.hide();
    },
  },
};
</script>
<style>
.body-container {
  max-width: 50rem;
}

* {
  scrollbar-color: #1a237e #e8eaf6;
  scrollbar-width: thin !important;
}
</style>
